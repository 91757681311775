// const swiper = require('swiper');
// import 'swiper/css';

$(function() {
    initSearch();
    initNav();
    initSlick();
    initAanbodSwiper();
    initDetails();
    initDetailsDropdown();
    initContact();
    initAanbod();
    initEmployees();
    initPackages();
    coockie();
});

$(window).load(function() {
    initHuisverkopen();
});

$(window).resize(function() {
    clearTimeout($.data(this, 'resizeTimer'));
    $.data(this, 'resizeTimer', setTimeout(function() {
        $nav.children('ul').attr('style', false);
    }, 200));
});

function initNav() {
    $btn_navigation = $('header .nav-toggle');
    $nav = $('header nav');

    $btn_navigation.click(function(e) {
        e.stopPropagation();
        e.preventDefault();

        if ($(window).width() < 980) {
            $nav.slideToggle(function() {
                if ($(this).is(':visible')) {
                    $('header .nav_wrapper .button_wrapper li.round span.icon-phone').css({
                        color: "#fff"
                    });
                } else {
                    $('header .nav_wrapper .button_wrapper li.round span.icon-phone').css({
                        color: "#333"
                    });
                }
            });
        }
    });

    if ($nav.find('ul').length > 0) {
        $li = $nav.find('li');

        $li.has('ul').children('a').click(function(e) {

            $(this).closest('ul').children('li').find('ul').each(function(i, ul) {
                if ($(ul) != $(this).next('ul')) {
                    $(this).prev('a').children('span').removeClass('toggle');

                    if ($(window).width() < 1024) {
                        $(ul).slideUp();
                    }
                }
            });

            if ($(this).next('ul').css('visibility') === 'hidden' || $(this).next('ul').is(':hidden')) {
                e.preventDefault();

                if ($(window).width() < 1024) {
                    $(this).next('ul').slideDown();
                }
                $(this).children('span').addClass('toggle');
            }
        });
    }

    if ($(this).scrollTop() > 0) {
        $('.nav_wrapper').addClass('scrolled');
    } else {
        $('.nav_wrapper').removeClass('scrolled');
    }
    $(window).scroll(function() {
        if ($(this).scrollTop() > 0) {
            $('.nav_wrapper').addClass('scrolled');
        } else {
            $('.nav_wrapper').removeClass('scrolled');
        }
    });
}

function initSearch() {
    $btn_search = $('header .search-toggle');
    $search = $('#search');

    if ($search.length == 0) {
        $btn_search.hide();
    } else {

        $btn_search.click(function(e) {
            e.stopPropagation();
            e.preventDefault();

            if ($(window).width() < 768) {
                $('header nav').slideUp(function() {
                    $search.slideToggle();
                });
            }
        });

        $('select#plaats option').first().text('Kies een plaats');
        $('select#prijs_min option').first().text('Minimum prijs');
        $('select#prijs_max option').first().text('Maximum prijs');
        $('select#prijs_min_huur option').first().text('Minimum prijs');
        $('select#prijs_max_huur option').first().text('Maximum prijs');

        $select_koophuur = $('#koophuur');
        $select_koophuur.change(function() {
            if ($select_koophuur.val() == 'koop') {
                $('#search-huurprijs select').attr('disabled', 'disabled');
                $('#search-koopprijs select').removeAttr('disabled');
                $('#search-huurprijs').hide();
                $('#search-koopprijs').show();
            } else {
                $('#search-koopprijs select').attr('disabled', 'disabled');
                $('#search-huurprijs select').removeAttr('disabled');
                $('#search-koopprijs').hide();
                $('#search-huurprijs').show();
            }
        });
        $select_koophuur.change();
    }
}

function initSlick() {
    if ($('.slick').length > 0) {
        $('.slick').slick({
            dots: true,
            arrows: false,
            infinite: false,
            speed: 300,
            slidesToShow: 3,
            slidesToScroll: 3,
            focusOnSelect: false,
            responsive: [{
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    }
                }
            ]
        });
    }
}

function initAanbodSwiper() {
    if (document.querySelector('#aanbodswiper .swiper-container')) {
        const swiper = new Swiper('#aanbodswiper .swiper-container', {
            slidesPerView: 1,
            breakpoints: {
                980: {
                    slidesPerView: 4,
                    spaceBetween: 0
                },
            }
        });
    }
}

// function initAanbodSlider() {
//     if ($('.aanbod-slider').length > 0) {
//         $('.aanbod-slider').slick({
//             dots: true,
//             arrows: false,
//             infinite: false,
//             speed: 300,
//             slidesToShow: 4,
//             slidesToScroll: 4,
//             focusOnSelect: false,
//             responsive: [{
//                     breakpoint: 1200,
//                     settings: {
//                         slidesToShow: 3,
//                         slidesToScroll: 3,
//                     }
//                 },
//                 {
//                     breakpoint: 768,
//                     settings: {
//                         slidesToShow: 2,
//                         slidesToScroll: 2,
//                     }
//                 },
//                 {
//                     breakpoint: 480,
//                     settings: {
//                         slidesToShow: 1,
//                         slidesToScroll: 1,
//                     }
//                 }
//             ]
//         });
//     }
// }

function initDetails() {
    if ($('#details').length > 0) {
        initDetailOmschrijving();
        initDetailSlider();
        initDetailButtons();
        // initStreetview();
        // initMaps();
        initObjectPano();

        if ($('.lightbox').length > 0) {
            $('.lightbox').magnificPopup({
                type: 'image',
                gallery: {
                    enabled: true
                }
            });
        }
    }
}

function initDetailsDropdown() {
    var button = $('#ObjectDetailsDropdown');
    var dropdownMenu = $('#dropdown-menu');

    button.on('click', function() {
        dropdown();
    })
    $('#dropdown-menu a').on('click', function() {
        dropdownMenu.removeClass('dropdown-menu').addClass('detail_buttons')
    })
    $(document).on('click', function(event) {
        if (!$(event.target).closest('.dropdown').length) {
            if (dropdownMenu.hasClass('dropdown-menu')) {
                dropdownMenu.removeClass('dropdown-menu').addClass('detail_buttons')
            }
        }
    });

    function dropdown() {
        if (dropdownMenu.hasClass('dropdown-menu')) {

        } else {
            dropdownMenu.addClass('dropdown-menu').removeClass('detail_buttons');
        }
    }

}

function initDetailButtons() {
    if ($('.detail_buttons').length > 0) {
        var sections = {},
            offset = 225;

        $('.detail_buttons a').click(function() {
            $('.detail_buttons li').removeClass('active');
            if($(this).hasClass('btn-green') == false){
                $(this).parent().addClass('active');
            }

            // adjust for fixed header
            if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') &&
                location.hostname == this.hostname) {

                var target = $(this.hash);
                target = target.length ? target : $('[id=' + this.hash.slice(1) + ']');
                if (target.length) {
                    $('html,body').animate({
                        scrollTop: target.offset().top - offset
                    }, 1000);
                    return false;
                }
            }
        });

        $('.detail_buttons a').each(function() {
            id = $(this).attr('href');

            // if (id !== '#') {
            //     sections[id] = $(id).offset().top;
            // }
        });

        var tabs_offset = $('#tabs').offset().top;

        $(document).scroll(function() {
            var pos = $(this).scrollTop();

            for (key in sections) {
                if (sections[key] > pos && sections[key] < pos + offset) {
                    $('.detail_buttons li').removeClass('active');
                    $('.detail_buttons a[href="' + key + '"]').parent().addClass('active');
                }
            }

            if ($(this).scrollTop() > tabs_offset) {
                $('#tabs').addClass('fixed');
                $('#tabs').css({
                    top: $('.nav_wrapper').outerHeight()
                });
                $('#tabs-buffer').css({
                    height: $('#tabs').outerHeight()
                });
            } else {
                $('#tabs').removeClass('fixed');
                $('#tabs-buffer').css({
                    height: 0
                });
            }
        });
    }
}

function initDetailSlider() {
    if ($('#details-slider').length > 0) {
        $slider = $('#details-slider');
        $thumbs = $('#details-thumbs');

        $slider.slick({
            dots: false,
            arrows: true,
            prevArrow: '<i class="prev icon-angle-left"></i>',
            nextArrow: '<i class="next icon-angle-left"></i>',

            infinite: true,
            speed: 300,
            slidesToShow: 1,
            slidesToScroll: 1,
            asNavFor: '#details-thumbs'
        });

        $thumbs.slick({
            dots: false,
            arrows: false,
            infinite: true,
            speed: 300,
            slidesToShow: 2,
            slidesToScroll: 1,
            vertical: true,
            verticalSwiping: true,
            asNavFor: '#details-slider'
        });
    }
}

function initStreetview() {
    var geocoder = new google.maps.Geocoder();
    geocoder.geocode({ 'address': $('#gmap_streetview').data('address') }, function(results, status) {
        var lookTo = results[0].geometry.location;
        if (status == google.maps.GeocoderStatus.OK) {
            var panoOptions = {
                position: lookTo,
                panControl: false,
                addressControl: false,
                linksControl: false,
                zoomControlOptions: false
            };
            var pano = new google.maps.StreetViewPanorama(document.getElementById('gmap_streetview'), panoOptions);
            var service = new google.maps.StreetViewService;
            service.getPanoramaByLocation(pano.getPosition(), 50, function(panoData) {
                if (panoData != null) {
                    var panoCenter = panoData.location.latLng;
                    var heading = google.maps.geometry.spherical.computeHeading(panoCenter, lookTo);
                    var pov = pano.getPov();
                    pov.heading = heading;
                    pano.setPov(pov);
                } else {
                    $('#gmap_streetview').html('Kan streetview niet genereren');
                }
            });
        } else {
            $('#gmap_streetview').html('Kan streetview niet genereren');
        }
    });
}

function initMaps() {
    var latlng = new google.maps.LatLng($('#gmap_locatie').data('lat'), $('#gmap_locatie').data('lng'));

    map = new google.maps.Map(document.getElementById("gmap_locatie"), {
        zoom: 15,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        center: latlng,
        scrollwheel: false
    });

    var marker = new google.maps.Marker({
        position: latlng,
        map: map,
        icon: '/img/icon-map.png'
    });
}

function initDetailOmschrijving() {
    if ($('a.meer').length > 0) {
        $('a.meer').click(function(e) {
            e.preventDefault();
            $('.description_short').slideUp(function() {
                $('.description_long').slideDown();
            });
        });
    }
}

function initContact() {
    if ($('#contact').length > 0) {
        var latlng = new google.maps.LatLng($('#maps').data('lat'), $('#maps').data('lng'));

        map = new google.maps.Map(document.getElementById("maps"), {
            zoom: 15,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            center: latlng,
            scrollwheel: false
        });

        var marker = new google.maps.Marker({
            position: latlng,
            map: map,
            icon: '/img/icon-map.png'
        });
    }
}

function initAanbod() {
    initViewToggle();
}

function initViewToggle() {
    if ($('.view-toggle').length > 0) {
        $('.view-map').hide();

        $('.view-toggle').click(function() {

            $('.view-toggle').removeClass('active');
            $(this).addClass('active');

            $('.view').hide();
            $('.' + $(this).data('toggle')).show();

            localStorage['view-toggle'] = $(this).data('toggle');

            if ($(this).data('toggle') == 'view-map') {

                $('.sort_wrapper').hide();

                var view = $(this).data('method');

                $.ajax({
                    dataType: "json",
                    url: window.location.origin + window.location.pathname + '.json' + window.location.search,
                    method: 'GET',
                    async: true
                }).done(function(data) {
                    initMap(data);
                });
            } else {
                $('.sort_wrapper').show();
            }
        });
    }

    if (localStorage['view-toggle'] == 'view-map') {
        $('.view-toggle[data-toggle=view-map]').click();
    }
}

function initMap(data) {
    var bounds = new google.maps.LatLngBounds();
    var infowindow = new google.maps.InfoWindow();

    map = new google.maps.Map(document.getElementById("maps"), {
        zoom: 12,
        mapTypeId: google.maps.MapTypeId.ROADMAP
    });

    markers = [];
    $(data).each(function(i, object) {

        var marker = new google.maps.Marker({
            position: new google.maps.LatLng(object.latitude, object.longitude),
            map: map,
            icon: '/img/icon-map.png'
        });

        bounds.extend(marker.position);

        google.maps.event.addListener(marker, 'click', (function(marker, i) {
            return function() {
                infoBubble = new InfoBubble({
                    map: map,
                    content: '<a href="' + object.url + '" class="object">\n\
                                <figure>\n\
                                    <img src="' + object.hoofdfoto + '" alt="' + object.adres + ' ' + object.plaats + '">\n\
                                    <figcaption>' + object.prijs + '</figcaption>' + object.label + '\n\
                                </figure>\n\
                                <div class="text">\n\
                                    <h2>' + object.adres + '</h2>\n\
                                    <h3>' + object.plaats + '</h3>\n\
                                </div>\n\
                            </a>',
                    shadowStyle: 0,
                    padding: 0,
                    borderRadius: 0,
                    arrowSize: 0,
                    borderWidth: 0,
                    minWidth: 320,
                    maxWidth: 320,
                    closeSrc: '/img/infobubble-close.png'
                });

                infoBubble.open(map, marker);
            }
        })(marker, i));

        markers.push(marker);
    });

    map.fitBounds(bounds);

    var mc = new MarkerClusterer(map, markers, {
        imagePath: '/img/m'
    });
}

function initObjectPano() {
    $('[id^=pano-]').each(function(index) {
        id = $(this).attr('data-id');
        data = {
            js: '/js/pano/pano.js',
            swf: '/js/pano/pano.swf',
            xml: window.location.origin + window.location.pathname + '/pano/' + id + '.xml',
            target: 'pano-' + id,
            html5: 'prefer',
            passQueryParameters: true
        };
        embedpano(data);
    });
}

function initEmployees() {
    if ($('.employee').length > 0 && $(window).width() > 1200) {
        $('.matchHeightEmployee').matchHeight();
    }
}

function initPackages() {
    if ($('#packages').length > 0) {
        $('.list:not(.list-dark):not(.list-price)').matchHeight({
            byRow: false
        });
    }
}

function initHuisverkopen() {
    if ($('#huisverkopen').length) {
        $('#huisverkopen .blocks').each(function() {
            var txtHeight = $(this).find('.text').outerHeight(),
                frpHeight = $(this).find('.text p').outerHeight(),
                titHeight = $(this).find('.text h2, .text h3').outerHeight(),
                imgHeight = $(this).find('.image').outerHeight(),
                smlHeight = frpHeight + titHeight + 115,
                totHeight = titHeight + 108,
                pAmount = 0;

            if (txtHeight > imgHeight) {
                $(this).find('.text').css({
                    height: smlHeight
                });

                $(this).find('.text').html($(this).find('.text').html() + '<div class="readmore"><a href="javascript:; void 0" class="btn">Lees meer</a></div>');

                $(this).find('.text p').each(function() {
                    totHeight = totHeight + $(this).outerHeight();
                    pAmount++;
                });

                totHeight = totHeight + (pAmount * 30);
            }

            $(this).find('.text .readmore a').click(function() {
                $(this).parents('.text').toggleClass('active');

                if ($(this).parents('.text').hasClass('active')) {
                    $(this).parents('.text').css({
                        height: totHeight
                    });
                } else {
                    $(this).parents('.text').css({
                        height: smlHeight
                    });
                }
            });
        });
    }
}

function coockie() {
    if (getCookie('popupDisable') == 'true') {
        document.getElementById('shape').style.display = 'none';
    }


    if (document.getElementById('disablePopupButton')) {

        document.getElementById('disablePopupButton').addEventListener("click", function (e) {
            e.preventDefault();
            e.target.closest('.shape').classList.add("disable");

            document.cookie = 'popupDisable=true'
            console.log(document.cookie)

            console.log(getCookie('popupDisable'))
        })
    }


    function getCookie(cname) {
        let name = cname + "=";
        let ca = document.cookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }
}